<template>
    <div>
        <headTitle :isMain="3"/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/02-1数字化生产.png"/>
        <div class="mobile-head-explain">
            <div class="mobile-head-explain-title">数字化生产</div>
            <div class="mobile-head-explain-content" style="font-size:3.3rem">打造透明、高效、稳定的数字化生产管理平台</div>
        </div>
        <div class="mobile-head-catalogue" >
            <div :class="{'title1':true,'ischecked':checkIndex==1} " @click="(checkIndex=1)"><span>业务痛点</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==2} " @click="(checkIndex=2)"><span>解决方案</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==3} " @click="(checkIndex=3)"><span>方案收益</span></div>
        </div>
        <div v-if="(checkIndex==1)" class="mobile-content-box1">
            <div class="ware-title" style="margin-top:6.2rem">工厂执行层</div>
            <div class="ware-content1" style="margin-top:1rem">每天手工抄写不同报表及各生产线运转情况，产线数据量大;<br/>
                问题反馈速率慢，人机、物料等待延时，造成损失;</div>
            <div class="ware-title" style="margin-top:4.8rem">工厂管理层</div>
            <div class="ware-content1" style="margin-top:1rem">依赖报表统计及层层上报汇总数据，可能存在些许误差且耗费时间;<br/>
                无法及时掌握设备停机及故障原因，只能通过层层人工判断做出决策;</div>
            <div class="ware-title" style="margin-top:4.8rem">集团管理层</div>
            <div class="ware-content1" style="margin-top:1rem;margin-bottom: 5rem;">被动等待各厂滞后的数据组合分析，很难实时获取工厂生产核心指标，进行生产运营决策的优化与预测。</div>
        </div>
        <div v-else-if="(checkIndex==2)" class="mobile-content-box1">
            <img class="ware-img" style="margin-bottom:5rem" src="../../assets/image/example/02-2解决方案.svg"/>
        </div>
        <div v-else-if="(checkIndex==3)" class="mobile-content-box1">
            <img class="ware-img2" style="margin-bottom:5rem;" src="../../assets/image/example/02-2方案收益.png"/>
            <div class="ware-title" style="margin-top:6.2rem;color: #11A84F;">工厂执行层</div>
            <div class="ware-content1" style="margin-top:1rem">基于IOT自动采集产线、产程、设备、能耗等数据进行统计记录，实时监控生产状况;<br/>
                提供可视化看板及报表工具，建立故障反馈机制，减少人工抄表时间，提高人员效率及设备稳定运行效率。</div>
            <div class="ware-title" style="margin-top:4.8rem;color: #11A84F;">工厂管理层</div>
            <div class="ware-content1" style="margin-top:1rem">根据采集数据进行组合分析及可视化展示，实现设备、能耗、人员透明化管理提供，降低管理成本;<br/>
                实现产线停机原因分析，建立多维度预警及故障分析体系框架，减少问题发生干预的滞后，提高生产效率。</div>
            <div class="ware-title" style="margin-top:4.8rem;color: #11A84F;">集团管理层</div>
            <div class="ware-content1" style="margin-top:1rem;margin-bottom: 5rem;">基于数据驱动，结合生产关键指标（ME、订单完成率等）及排序分析，实现生产运营的预测和决策；<br/>
                提供集团驾驶舱，精益看板管理，实现运营成本降低及生产力提高。</div>
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 1,
            showDescription:false,
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
        };
    },

    mounted() {
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.description{
    display: flex;
    width: 66.5rem;
    margin: auto;
    margin-top: 6rem;
    .title1{
        font-size: 2.8rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #11A84F;
        line-height: 4.2rem;
    }
    .title2{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 4.2rem;
        margin-left: 70%;
    }
}
.description-box{
    max-width: 66.5rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.mobile-head-catalogue{
    width: 100%;
    height: 9rem;
    display: flex;
    background: #F8F8F8;
    .title1{
        width: 20rem;
        height: 9rem;
        display: flex;
        text-align: center;
        align-items: center;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #4F4F4F;
            line-height: 2rem;
            margin: auto;
        }
    }
    .ischecked{
        background: #EDEDED;
        border-bottom: 2px solid #11A84F;
    }
}
.mobile-content-box1{
    width: 66rem;
    margin: auto;
    margin-top: 3rem;
}
.ware-content1{
    max-width: 66rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
}
.ware-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
}
.ware-box1{
    width: 66.8rem;
    height: 10rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    .title1{
        width: 58.8rem;
        height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem; 
        margin: auto; 
        align-items: center;
    }
}
.ware-img{
    width: 100%;
    height: 40.7rem;
    margin: auto;
}
.ware-img2{
    width: 70%;
    // height: 63.3rem;
    margin: auto;
    display: flex;
    text-align: center;
}
</style>